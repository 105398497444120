@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.container-fluid {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
}
@media (min-width: 576px) {
  .container-fluid {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    margin: 0 auto;
  }
}

/* .modal {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
  background-color: var(--black40);
  top: 15%;

  outline: none;
  border-radius: 10px;
  border: 1px solid var(--shadow10);
} */

/* .modal{
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: center;
  z-index: 11;
  margin: auto;
  max-width: 500px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  animation-duration: 1s;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 576px) {
  .modal {
    max-width: 572px;
  }
}

@media (min-width: 1024px) {
  .modal {
    max-width: 900px;
  }
} */

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .e-profile .table-item ul.overview_list li {
    width: calc(100% / 3);
  }
}

@media (min-width: 768px) {
  .e-district .table-item ul.overview_list li {
    width: calc(100% / 3);
  }
}

@media (min-width: 768px) {
  .e-upazila .table-item ul.overview_list li {
    width: calc(100% / 4);
  }
}
